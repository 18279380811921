<template>
  <div>
    <UpdatePermissionForm v-if="ready" :permission="permission"/>
    <div v-else>
      <b-row>
        <b-col/>
        <b-col cols="1">
          <AppPulsingIcon/>
        </b-col>
        <b-col/>
      </b-row>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

import UpdatePermissionForm from "@/components/permissions/UpdatePermissionForm";
import AppPulsingIcon from "@/components/AppPulsingIcon";

export default {
  components: {
    UpdatePermissionForm,
    AppPulsingIcon,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    ...mapState({
      permission: (state) => state.permissions.permission,
    }),
  },
  async created() {
    try {
      await this.$store.dispatch("permissions/fetchPermission", this.uid);
    } catch (e) {
      await this.$swal.fire({
        icon: "error",
        title:
            "Une erreur est survenue lors de la récupération de la permission",
        text: e.message,
      });
      await this.$router.push({name: 'permissions'})
      return;
    }
    this.ready = true;
  },
};
</script>

